<template>
  <div>
    <div class="container">
      <div class="row">
        <div class="col-md-12">
        <div class="text-center align-middle">
          <div class="spinner-border" role="status">
            <span class="sr-only"></span>
          </div>
        </div>
      </div>

        </div>
    </div>
  </div>
</template>

<script>
import "../assets/css/FormSpinLoader.css"
export default {
  name: "PageLoader",
  mounted(){
    
  }
};
</script>

<style scoped>


</style>