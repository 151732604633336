<template>
  <div>
    <div v-if="!inputFields.pageloader">
      <div v-if="!isUserDetailsDisplayed && !isUserEdit" class="main">
        <div>
          <img class="logo" src="../assets/img/logo.png" />
        </div>
        <br />
        <div>
          <h2>APIBridge Account Management</h2>
        </div>
        <form v-on:submit.prevent="authenticateFormSubmit">
          <div>
            <input
              type="text"
              name=""
              class="input"
              v-model.lazy.trim="inputFields.name"
              @change="nameChange"
              :disabled="isAuthFormDisable"
              placeholder="Username"
              id=""
            />
            <span v-if="validation.isNameError" class="errorText text-danger"
              >You need to Enter Your Name</span
            >
          </div>
          <div>
            <div class="rememberMe">
              <input
                type="checkbox"
                :disabled="isAuthFormDisable || !isUserNameValid"
                v-model="inputFields.isLoginByPassword"
                name=""
                id=""
              />
              <label>Login By Password</label>
            </div>

            <div v-if="inputFields.isLoginByPassword">
              <input
                type="password"
                name=""
                v-model.lazy.trim="inputFields.password"
                :disabled="
                  isAuthFormDisable ||
                  !inputFields.isLoginByPassword ||
                  !isUserNameValid
                "
                class="input"
                placeholder="Password"
                id=""
              />
              <span
                v-if="validation.isPasswordError"
                class="errorText text-danger"
                >You need to Enter Your Password</span
              >
            </div>

            <div v-if="!inputFields.isLoginByPassword">
              <div>
                <button
                  type="button"
                  @click="getUserEmailAndSendOtp()"
                  :disabled="isAuthFormDisable || !isUserNameValid"
                  class="btn btn-primary sendOtpBtn"
                >
                  Send OTP
                </button>
              </div>
              <div v-if="isOtpSend && isUserNameValid">
                <label class="form-check-label text-success" for="gridCheck1">
                  OTP Sent To Your Email.
                </label>
              </div>
              <div v-if="isOtpSend && isUserNameValid">
                <input
                  type="otp"
                  name=""
                  @change="
                    () => {
                      verifyOtp();
                    }
                  "
                  class="input"
                  placeholder="OTP"
                  v-model.lazy="inputFields.otp"
                  :disabled="isAuthFormDisable"
                />
              </div>
            </div>
          </div>

          <div>
            <div class="loginContainer">
              <button
                class="loginBtn"
                :disabled="
                  isAuthFormDisable ||
                  !(isUserNameValid && (isPasswordValid || isOtpVerified))
                "
                type="submit"
              >
                Login
              </button>
            </div>
            <div v-if="!isUserNameValid" class="loginContainer">
              <router-link
                class="linkStyleNone"
                :to="{ name: 'registerRoute', params: { formName: 'algoji' } }"
                ><button type="button" class="loginBtn">
                  Create New Account
                </button></router-link
              >
            </div>
          </div>
        </form>
      </div>
      <div
        v-if="isUserDetailsDisplayed && userDetails.data"
        class="userDetailsDisplayContainer userDetailsDisplayContainerSpace"
      >
        <div class="EditheadingContainer">
          <h3>Account Information</h3>
        </div>
        <div>
          <UserAccountInformation />

          <button
            class="fetch_button"
            @click="
              isUserEdit = true;
              isUserDetailsDisplayed = false;
            "
          >
            Edit / Renew
          </button>
        </div>
      </div>
      <div
        v-if="isUserEdit"
        class="userDetailsEditContainer userDetailsDisplayContainerSpace"
      >
        <div class="UserEditHeadingContainer">
          <h3>Manage Information</h3>
        </div>
        <UserAccountEditForm :AUTH_DETAILS="AUTH_DETAILS" />
        <hr />
      </div>

      <div class="policyLinks">
        <!-- ckncsdkcn -->
        <router-link class="policyLink" to="/usage-policy"
          >Acceptabe Use Policy</router-link
        >
        <router-link class="policyLink" to="/refund-policy"
          >Refund Policy</router-link
        >
        <router-link class="policyLink" to="/privacy-policy"
          >Privacy Policy</router-link
        >
        <!-- <router-link to="/refund-policy">cschdchd</router-link> -->
      </div>
      <div class="copyRightContainer">
        <span class="copyRightText"
          >Copyright © 2021 Algoji Enterprises Pvt Ltd. All rights
          reserved.</span
        >
      </div>
    </div>
    <div v-if="inputFields.pageloader">
      <PageLoader />
    </div>
  </div>
</template>



<script> 
import '../assets/css/Common.css';
import '../assets/css/RegisterFormStyle.css';
import { dynamicFormData } from "../state/dynamicFormData.js";
import { userDetails } from "../state/userDetails.js";
import { URLS } from "../state/URLS.js";

import UserAccountInformation from "../components/UserAccountInformation.vue";
import UserAccountEditForm from "../components/UserAccountEditForm.vue";
import PageLoader from "../components/PageLoader.vue";
export default {
  name: "RootPage",
  components: {
    UserAccountInformation,
    PageLoader,
    UserAccountEditForm,
  },
  data() {
    return {
      dynamicFormData,
      userDetails,
      URLS,
      api_base_url: process.env.VUE_APP_API_URL,
      demo_api_base_url: process.env.VUE_APP_DEMO_API_URL,
      admin_base_url: process.env.VUE_APP_ADMIN_API_URL,
      proxyUrl: "",
      formDetails: {
        formName: "",
        isFormLoaded: false,
        isFormLoadedError: false,
        formLoadedErrorText: "",
        formTitle: "",
      },
      AUTH_DETAILS: {
        ADMIN_API_TOKEN: "",
        Admin_id: "",
        API_TOKEN: "",
        USER_TOKEN: "",
      },
      validation: {
        isUserExist: false,
        isNameError: false,
        isPasswordError: false,
        isEmailError: false,
        isPhoneError: false,
        isOtpError: false,
      },
      otpToken: "",
      isOtpVerified: false,
      isOtpSend: false,
      showChangedPassword: false,
      isUserDetailsDisplayed: false,
      isUserEdit: false,
      isAuthFormDisable: false,
      inputFields: {
        name: "", // CHANGE CODE
        password: "", // CHANGE CODE
        userDetails: {
          email: "",
          phone: "",
          broker: {},
          license: {},
          licenceArr: [],
          NumberOfLicense: 0,
          amount: 0,
          SubscriptionExpiryDate: "",
          newLicenseSelected: null,
          existingLicense: [],
          stags: [],
        },
        pageloader: false,
        email: "",
        phone: "",
        isShowing: false,
        isLoginByPassword: false, // CHANGE CODE
        // isLoginByPassword: true,// CHANGE CODE
        license: "PaperTrading",
        amount: 0,
        otp: "",
      },
    };
  },
  async mounted() {
    // this.formDetails.formName = "algoji";
    // this.dynamicFormData.data = await this.fetchFormData();
    // this.setFormData(this.dynamicFormData.data);
    // document.title = "apibridge.app"
    // await this.authenticateFormSubmit()
    // this.isUserEdit= true;
    // this.isUserDetailsDisplayed= false;
    
  },
  methods: {
    nameChange() {
      this.isOtpSend = this.isOtpVerified = false;
    },
    async fetchFormData() {
      const fetchFormDataUrl = `${this.proxyUrl}${this.api_base_url}api/forms/Template/${this.formDetails.formName}`;

      this.formDetails.isFormLoaded = false;

      try {
        const formDataResp = await fetch(fetchFormDataUrl, {
          method: "GET",
          redirect: "follow",
        });

        const reqStatusCode = formDataResp.status;

        const formDataObj = await formDataResp.json();

        const formDataValue = formDataObj.data; //Need to check whetheer form data

        this.formDetails.isFormLoaded = true;
        if (reqStatusCode != 200) {
          this.formDetails.isFormLoadedError = true;
          this.formDetails.formLoadedErrorText = "Something Went Wrong";
          return false;
        }
        if (formDataObj.code != 200) {
          this.formDetails.isFormLoadedError = true;
          this.formDetails.formLoadedErrorText = "Form Not Found";
          return false;
        }
        if (formDataObj.error) {
          this.formDetails.isFormLoadedError = true;
        }

        this.formDetails.isFormLoaded = true;
        this.formDetails.isFormLoadedError = false;
        return formDataValue;
      } catch (e) {
        this.formDetails.isFormLoaded = true;
        this.formDetails.isFormLoadedError = true;
        this.formDetails.formLoadedErrorText = "Something Went Wrong";
        return false;
      }
    },
    async getUserStags() {
      const getStageUrl = `${this.proxyUrl}${this.URLS.admin_base_url}/Admin/userFeatures?UserID=${this.userDetails.data.ID}`;
      const getStageHeader = new Headers();
      getStageHeader.append(
        "Authorization",
        `${this.AUTH_DETAILS.ADMIN_API_TOKEN}`
      );

      const requestObj = {
        method: "GET",
        headers: getStageHeader,
      };
      const getStageResp = await fetch(getStageUrl, requestObj);
      const { data } = await getStageResp.json();
      this.inputFields.userDetails.stags = data;
      return data;
    },
    getJsonHeaders() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    getJsonHeader() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    async getAllLicence() {
      this.userDetails.data.licenceArr = [];
      const getAllLicenceUrl = `${this.proxyUrl}${this.api_base_url}api/forms/FetchLicenseList`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append(
        "Authorization",
        `Bearer ${this.userDetails.data.Token}`
      );
      const reqObj = {
        headers: jsonHeader,
        method: "GET",
        redirect: "follow",
      };
      const getLicenceResp = await fetch(getAllLicenceUrl, reqObj);
      const getLicenceJson = await getLicenceResp.json();

      const licenceArr = getLicenceJson.data;

      this.userDetails.data.licenceArr = licenceArr;
      if (this.userDetails?.data?.licenceArr?.length) {
        this.inputFields.userDetails.license = this.userDetails.data.licenceArr[0];
      }
    },
    async authenticateFormSubmit() {
      
      const isLoginByPassword = this.inputFields.isLoginByPassword;
      if (!isLoginByPassword) {
        const isOtpCorrect = await this.verifyOtp();
        if (isOtpCorrect) {
          // alert("Login Successfull");
          this.inputFields.pageloader = true;
          this.$swal({
            title: "Success",
            text: "Login Successfull",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

          // this.userDetails.data = await this.fethUserDetailByUserName();

          await this.displayUserDetails();
          this.isUserDetailsDisplayed = true;

          this.isAuthFormDisable = true;
          this.inputFields.pageloader = false;
        } else {
          alert("Please Enter correct Details");
          return;
        }
      } else {
        const isLoginSuccessfull = await this.authenticateWithPassword();
        if (isLoginSuccessfull) {
          // alert("Login Successfull");

          this.$swal({
            title: "Success",
            text: "Login Successfull",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

          this.inputFields.pageloader = true;
          // this.userDetails.data = await this.fethUserDetailByUserName();
          await this.displayUserDetails();
          this.isUserDetailsDisplayed = true;
          this.inputFields.pageloader = false;
          this.isAuthFormDisable = true;
        } else {
          alert("Please Enter correct Details");
          return;
        }
      }
    },
    async authenticateWithPassword() {
      if (this.isUserNameValid && this.isPasswordValid) {
        const checkUsernamPasswordUrl = `${this.proxyUrl}${this.URLS.admin_base_url}/Login/SubsUser`;
        const jsonHeader = this.getJsonHeader();
        const checkUsernamPasswordRequestObj = {
          method: "POST",
          headers: jsonHeader,
          redirect: "follow",
          body: JSON.stringify({
            Username: this.inputFields.name,
            Password: this.inputFields.password,
          }),
        };
        try {
          const authResp = await fetch(
            checkUsernamPasswordUrl,
            checkUsernamPasswordRequestObj
          );

          const statusCode = authResp.status;
          if (statusCode == 401) {
            return false;
          }

          const userData = await authResp.json();
          // const userData = authObj.data;
          this.AUTH_DETAILS.USER_TOKEN = userData.Token;
          if (userData.Token) {
            debugger;
            this.userDetails.data = userData
            ;
            return true;
          }
        } catch (e) {}
      }
    },
    async fetchCurrentBroker() {
      if (this.userDetails.data.AdminBrokerID) {
        const fetch_Current_Broker_Url = `https://emailclient.conveyor.cloud/api/Utils/FetchBrokerByBrokerId/`;
        const jsonHeader = this.getJsonHeaders();
        const requestObject = {
          method: "POST",
          headers: jsonHeader,
          body: JSON.stringify({
            brokerId: this.userDetails.data.AdminBrokerID,
          }),
        };
        const currentBrokerResp = await fetch(
          fetch_Current_Broker_Url,
          requestObject
        );
        const currentBrokerRespJson = await currentBrokerResp.json();
        if (currentBrokerRespJson.code == 200) {
          this.userDetails.data.selectedBroker = JSON.parse(
            currentBrokerRespJson.data
          );
        }
      }
    },
    async displayUserDetails() {
      
      // console.log(this.userDetails.data)
      this.inputFields.userDetails.email = this.userDetails.data.Email;
      this.inputFields.userDetails.phone = this.userDetails.data.dataMobile;
      this.inputFields.userDetails.NumberOfLicense = this.userDetails.data.NumberOfLicense;
      const expiryDate = this.userDetails.data.ExpiryDate; 
      debugger;
      this.inputFields.userDetails.SubscriptionExpiryDate = this.userDetails.data.ExpiryDate = this.userDetails.data.ExpiryDate.split("-").reverse().join("-")

      // this.inputFields.userDetails.SubscriptionExpiryDate = new Date(
      //   this.userDetails.data.ExpiryDate
      // ).toLocaleDateString("en-Ca");
      
      // this.userDetails.data.ExpiryDate = this.userDetails.data.ExpiryDate
      // this.userDetails.data.ExpiryDate = new Date(
      //   this.userDetails.data.ExpiryDate
      // ).toLocaleDateString("en-Ca");
      this.inputFields.userDetails.license.ModuleID = this.userDetails.data.ModuleID = this.userDetails.data.MyModule.split(
        "M"
      )[1];
      if (this.dynamicFormData?.data?.BrokerList?.length) {
        if (this.dynamicFormData?.data?.BrokerList?.length > 1) {
          this.inputFields.userDetails.broker = this.dynamicFormData.data.BrokerList.filter(
            (broker) =>
              broker.BrokerValue == this.userDetails.data.AdminBrokerID
          )[0];
        } else {
          this.inputFields.userDetails.broker = this.dynamicFormData.data.BrokerList[0];
        }
      }

      // await this.fetchCurrentBroker();
      // 
      await this.getAllLicence();
      
      if (this.userDetails.data.licenceArr?.length) {
        const currentLicense = this.userDetails.data.licenceArr.filter(
          (license) => this.userDetails.data.ModuleID == license.module_type
        )[0];
        // 
        this.userDetails.data.currentLicense = currentLicense;
      }
      // this.inputFields.displayUserDetails
      // this.getUserStags();
      // this.inputFields.userDetails.broker =
      // this.inputFields.userDetails.expiraryDate =
      // this.inputFields.userDetails.amount =
    },
    async sendEmailOtp() {
      const userName = this.inputFields.name;
      const fetchUserEmailUrl = `${this.proxyUrl}${this.URLS.api_base_url}api/login/SendAuthOTP?username=${userName}`;

      const getEmailOtpResp = await fetch(fetchUserEmailUrl);
      const emailOtpObj = await getEmailOtpResp.json();
      
      this.isOtpSend = emailOtpObj.OtpToken != "";
      if (this.isOtpSend) {
        this.otpToken = emailOtpObj.OtpToken;
        this.isOtpSend = true;
      }
    },
    async getUserEmailAndSendOtp() {
      //fetch email
      this.isOtpSend = false;
      this.isOtpVerified = false;
      if (this.isUserNameValid) {
        this.sendEmailOtp();
        // this.userDetails.data = await this.fethUserDetailByUserName();
        // 
        // if (this.userDetails.data) {
        //   this.emailOtpSend();
        // } else {
        //   alert("Please enter correct username");
        //   return false;
        // }
      }
      //send otp
      //verify otp
    },
    async emailOtpSend() {
      this.isOtpSend = false;
      this.isOtpVerified = false;
      // 
      if (this.userDetails.data.Email) {
        const payload = JSON.stringify({
          Name: this.userDetails.data.Name,
          Email: this.userDetails.data.Email,
          isLicenseAuth: true,
        });
        // 
        const otpSendUrl = `${this.proxyUrl}${this.api_base_url}api/forms/RequestOTP`;
        // 
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", this.AUTH_DETAILS.API_TOKEN);

        const resp = await fetch(otpSendUrl, {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: payload,
        });
        const respObj = await resp.json();
        this.isOtpSend = respObj.code == 200 && respObj.data != "";
        if (this.isOtpSend) {
          this.otpToken = respObj.data;
        }
      } else {
        //Display Error
      }
    },
    async verifyOtp() {
      
      this.isOtpVerified = false;
      const isNameValid = this.isUserNameValid;
      if (isNameValid && this.inputFields.otp) {
        const verifyOTPUrl = `${this.proxyUrl}${this.api_base_url}api/login/GetValidUserDetails?OTP=${this.inputFields.otp}`;

        const headers = new Headers();
        headers.append("Authorization", `Bearer ${this.otpToken}`);
        headers.append("Content-Type", "application/json");

        const body = JSON.stringify({
          OTP: this.inputFields.otp,
        });
        const requestObject = {
          redirect: "follow",
          headers,
          method: "GET",
        };
        const verifyResp = await fetch(verifyOTPUrl, requestObject);

        const verifyRespObj = await verifyResp.json();
        const isOtpVerified = (this.isOtpVerified = verifyRespObj.UserId != "");
        if (isOtpVerified) {
          this.userDetails.data = verifyRespObj;
        }
        return isOtpVerified;
      }
    },
    async fethUserDetailByUserName() {
      return;
      const fetchUserDetailsByUserName = `${this.proxyUrl}${this.URLS.admin_base_url}/Admin/profile?Username=${this.inputFields.name}`.trim();
      const jsonHeader = this.getJsonHeader();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const requestObj = {
        method: "GET",
        headers: jsonHeader,
        redirect: "follow",
      };

      const userDetailsResp = await fetch(
        fetchUserDetailsByUserName,
        requestObj
      );
      const userDetailsRespInJson = await userDetailsResp.json();
      const userDetails = userDetailsRespInJson.data;

      return userDetails;
    },
    setFormData(formData) {
      this.AUTH_DETAILS.API_TOKEN = "Bearer " + formData.Token;
      this.AUTH_DETAILS.ADMIN_API_TOKEN = "Bearer " + formData.Admin.Token;
      // this.AUTH_DETAILS.ADMIN_API_TOKEN = this.AUTH_DETAILS.API_TOKEN ;

      this.AUTH_DETAILS.Admin_id = formData.Admin.AdminID;
    },
  },
  computed: {
    isUserNameValid() {
      const username = this.inputFields.name;
      if (username != undefined && username != null) {
        return username.trim() != "";
      }

      return false;
    },
    isPasswordValid() {
      const password = this.inputFields.password;
      if (password != undefined && password != null) {
        return password.trim() != "";
      }

      return false;
    },

    getExistingLicense() {
      // console.log(this.userDetails.data.ModuleID)

      return this.userDetails.data.licenceArr.filter(
        (license) => this.userDetails.data.ModuleID == license.module_type
      );
    },
    getBrokerSelected() {
      return this.dynamicFormData.data.BrokerList.filter(
        (broker) => broker.BrokerValue == this.userDetails.data.AdminBrokerID
      )[0];
    },
    getNewLicenseExtendedDate() {
      const newLicenseSelected = this.inputFields.userDetails
        .newLicenseSelected;
      if (newLicenseSelected != null && newLicenseSelected != "null") {
        let startDate = new Date();
        const lastLicenseDate = new Date(
          this.userDetails.data.SubscriptionExpiryDate
        );
        lastLicenseDate.getTime() > startDate.getTime()
          ? (startDate = lastLicenseDate)
          : "";
        const numberOfNewLicense = newLicenseSelected.number_of_license;

        startDate.setDate(startDate.getDate() + 30 * numberOfNewLicense);
        return startDate.toLocaleDateString("en-CA");
      } else {
        return new Date(
          this.userDetails.data.SubscriptionExpiryDate
        ).toLocaleDateString("en-CA");
      }
    },
  },
};
</script>



<style>
.fetch_button {
  background: rgb(92, 148, 189);
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgb(255, 255, 255);
  font-family: "ProximaNova Regular", sans-serif;
  padding: 8px 67px;
  cursor: pointer;
}
</style>
<style scoped>
.copyRightContainer {
  display: flex;
  justify-content: center;
}
.copyRightContainer {
  font-size: 0.75rem;
  cursor: pointer;
}
.copyRightContainer :hover {
  text-decoration: underline;
}
@media (max-width: 400px) {
  .copyRightContainer {
    /* font-size: 0.7rem; */
    padding: 10px 2px;
  }
}
.linkStyleNone {
  text-decoration: none;
}
.row {
  flex-wrap: wrap;
  align-items: center;
  justify-items: flex-start;
}
.userDetailsDisplayContainerSpace {
  /* max-resolution: ; */
  /* width:100%; */
  margin: 20px;
  background: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
}
.loginContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.sendOtpBtn {
  margin-bottom: 1rem;
}
body,
html {
  display: block;
  box-sizing: border-box;
}
body {
  width: 100vw;
  padding: 0;
  margin: 0;
}
.input {
  min-width: 275px;
  overflow: hidden;
  border-radius: 25px;
  height: 35px;
  /* text-align: center; */
  margin-bottom: 20px;
  outline: none;
  border: 1;
  font-size: 16px;
  padding: 2px 20px;
  border: 1px solid grey;
}
.policyLinks {
  /* display: flex; */
  /* margin-right: 10rem; */
  /* justify-content: flex-end; */
  /* height: calc(100vh - 40%); */
  margin-top: 8rem;
  display: flex;
  /* min-height: 98vh; */
  align-items: flex-end;
  justify-content: flex-end;
  /* text-decoration: none; */
  color: black;
  /* position: absolute; */
  /* bottom: 0%; */
  /* right: 2%; */
}
.loginBtn {
  border-radius: 25px;
  height: 50px;
  text-align: center;
  display: block;
  padding: 0 20px;
  min-width: 125px;
  background-color: white;
  font-size: 18px;
  cursor: pointer;
  /* margin-right: 40px; */
  outline: none;
  border: 1px solid grey;
}
.rememberMe {
  padding-top: 5px;
  padding-bottom: 35px;
}

* {
  font-family: "Samim", sans-serif;
}
.logo {
  height: 135px;
}
.main {
  /* position: absolute; */
  /* top: 43%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  margin-top: 5rem;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
}
.policyLink {
  color: black;
  font-weight: 500;
  margin-left: 15px;
}
textarea:focus,
input:focus,
button:focus {
  outline: none;
}
a {
  /* text-decoration: none; */
}
body {
  min-height: 100vh;
}
form label {
  font-size: 16px;
  margin: 0 1.2em;
  color: rgb(88, 88, 88);
}
@media only screen and (max-height: 600px) {
  .main {
    margin-top: 1rem;
  }
  .policyLinks {
    margin-top: 4rem;
  }
}
</style>


