<template>

   <div class="userAccountInformationComponent">   
    
    <div class="form-group row">
      <label for="inputEmail" class="col-sm-2 col-form-label">Name</label>
      <div class="">
        <input
          type="email"
          class="form-control"
          :value="userDetails.data.Name"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="col-sm-2 col-form-label"
        >License<span class="text-danger">*</span></label
      >
      <div v-if="userDetails.data.currentLicense" class="">
        <select disabled class="form-control">
          <option
            :value="userDetails.data.currentLicense"
          >
            {{ userDetails.data.currentLicense.license_name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Expiry</label>
      <div class="">
        <input
          type="date"
          disabled
          class="form-control"
          v-model="
            userDetails.data.ExpiryDate
          "
        />
      </div>
    </div>
    <div v-if="userDetails.data.selectedBroker"  class="form-group row">
      <label class="col-sm-2 col-form-label"
        >Broker API<span class="text-danger">*</span></label
      >
      <div class="">
          <input
          type="text"
          disabled
          class="form-control"
          :value="userDetails.data.selectedBroker.broker_name"
        />
      </div>
    </div>

     <div class="form-group row">
      <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
      <div class="">
        <input
          type="email"
          class="form-control"
          v-model.lazy.trim="userDetails.data.Email"
          disabled
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="phone" class="col-sm-2 col-form-label">Phone</label>
      <div class="">
        <input
          type="tel"
          class="form-control"
          name="phone"
          id="phone"
          disabled
          v-model.lazy.trim="userDetails.data.Mobile"
        />
      </div>
    </div>
   </div>
    
</template>

<script>
import {userDetails} from "../state/userDetails.js"

export default {
    name:"UserAccountInformation",
    data(){
        return {
            userDetails
        }
    },
    methods:{

    }
};
</script>

<style >

.userDetailsDisplayContainer{
      display: flex;
      /* align-content: center; */
      /* justify-content: center; */
      text-align: center;
      align-items: center;
    flex-direction: column;
}
</style>
<style  scoped>

  .row{
  flex-wrap:wrap;
  align-items: center;
  justify-items: flex-start;
}
.form-control{
  min-width: 14rem;
  margin: auto 10px;
}
</style>
<style scoped>
.form-group label{
  margin: 10px;
} 
</style>