<template>
  <form v-on:submit.prevent="licenseRenewFormEditSubmit">
    <div class="form-group row">
      <label for="inputEmail" class="col-sm-2 col-form-label">Email</label>
      <div class="">
        <input
          type="email"
          class="form-control"
          v-model.lazy.trim="inputFields.userDetails.email"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="phone" class="col-sm-2 col-form-label">Phone</label>
      <div class="">
        <input
          type="tel"
          class="form-control"
          name="phone"
          id="phone"
          v-model.lazy.trim="inputFields.userDetails.phone"
        />
      </div>
    </div>
    <div class="form-group row">
      <div class="col-sm-2">
        <label for="" class="col-sm-2 col-form-label">Change Password</label>
      </div>
      <div class="col-sm-10">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="inputFields.showChangedPassword"
            :disabled="disabledFields.changePassword"
            id="gridCheck1"
          />
          <label class="form-check-label" for="gridCheck1">
            Check this to change Password
          </label>
        </div>
      </div>
    </div>
    <div v-if="inputFields.showChangedPassword">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">New Password</label>

        <input
          type="password"
          v-model.lazy="inputFields.NewPassword"
          class="form-control"
          id="inputState"
        />
      </div>
    </div>
    <div v-if="inputFields.showChangedPassword">
      <div class="form-group row">
        <label class="col-sm-2 col-form-label">Confirm Password</label>

        <input
          type="password"
          v-model.lazy="inputFields.ConfirmPassword"
          class="form-control"
          id="inputState"
        />
      </div>
    </div>



    <div v-if="isNewLicenseListVisible" class="form-group row">
      <div class="col-sm-2">
        <label class="col-sm-2 col-form-label"> Change Broker</label>
      </div>
      <div class="col-sm-10">
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            :disabled="disabledFields.changeBroker"
            v-model="inputFields.showChangeBroker"
            id="gridCheck1"
          />
          <label class="form-check-label" for="gridCheck1">
            Check this to change Broker
          </label>
        </div>
      </div>
    </div>
    <div
      v-if="
        inputFields.showChangeBroker && userDetails.data.BrokerList.length > 1
      "
      class="form-group row"
    >
      <label class="col-sm-2 col-form-label"
        >Change Broker API<span class="text-danger">*</span></label
      >
      <div class="">
        <select v-model="inputFields.userDetails.broker" class="form-control">
          <option
            v-for="(broker, index) in userDetails.data.BrokerList"
            v-bind:value="broker"
            v-bind:key="index"
          >
            {{ broker.BrokerName }}
          </option>
        </select>
      </div>
    </div>

    <div v-if="isNewLicenseListVisible" class="form-group row">
      <label class="col-sm-2 col-form-label">New License</label>
      <div class="">
        <select
          class="form-control"
          :disabled="disabledFields.addLicense"
          @change="getNewLicenseExtendedDate"
          v-model="inputFields.userDetails.newLicenseSelected"
        >
          <option value="null">None</option>
          <option
            v-for="(licence, index) in newLicenseList"
            v-bind:value="licence"
            v-bind:key="index"
          >
            {{ licence.license_name }}
          </option>
        </select>
      </div>
    </div>
    <div
      v-if="
        inputFields.userDetails.newLicenseSelected != null &&
          inputFields.userDetails.newLicenseSelected != 'null'
      "
      class="form-group row"
    >
      <div class="form-group row dynamicField">
        <label class="col-sm-2 col-form-label">Amount</label>

        <input
          type="text"
          class="form-control"
          disabled
          :value="newLicenseAmount"
        />
      </div>
    </div>
    <div class="form-group row">
      <label for="inputEmail3" class="col-sm-2 col-form-label">Expiry</label>
      <div class="">
        <input
          type="date"
          disabled
          class="form-control"
          :value="userDetails.data.ExpiryDate"
        />
      </div>
    </div>
    <div
      v-if="
        inputFields.userDetails.newLicenseSelected != null &&
          inputFields.userDetails.newLicenseSelected != 'null' &&
          inputFields.NewLicenseExtendedDate != null &&
          inputFields.NewLicenseExtendedDate != 'null'
      "
      class="form-group row"
    >
      <label for="inputEmail3" class="col-sm-2 col-form-label"
        >Expiry After Purchasing License</label
      >
      <div class="">
        <input
          type="date"
          :value="
            new Date(inputFields.NewLicenseExtendedDate).toLocaleDateString(
              'en-CA'
            )
          "
          disabled
          class="form-control"
        />
      </div>
    </div>

    <div class="form-group row">
      <div class="col-sm-10">
        <button
          type="submit"
          :disabled="isSubmitBtnDisabled"
          class="fetch_button sign_button"
          :class="{ disabled: isSubmitBtnDisabled }"
        >
          Submit
        </button>
        <!-- <button type="reset" class="fetch_button reset_button">Reset</button> -->
      </div>
    </div>
  </form>
</template>

<script>
import { userDetails } from "../state/userDetails.js";
import { validationRegex } from "../state/validationRegex.js";
import { dynamicFormData } from "../state/dynamicFormData.js";
import fetchPaymentObj from "../hooks/fetchPaymentObj.js";
import verifyRazorpayPayment from "../hooks/verifyRazorpayPayment.js";
import { URLS } from "../state/URLS.js";

export default {
  props: ["AUTH_DETAILS"],
  async mounted() {
    await this.fetchBrokerList()
    await this.displayUserDetails();

    // this.inputFields.userDetails.newLicenseSelected = {
    //   license_name: "Live Trading + Club Membership 360 Days",
    //   module_type: 2,
    //   number_of_license: 12,
    // };
    // this.getNewLicenseExtendedDate();
    // this.addNewLicense();
  },
  data() {
    return {
      dynamicFormData,
      userDetails,
      URLS,
      newData:{},
      inputFields: {
        newLicenseAmount: 0,
        name: "",
        password: "",
        showChangeBroker: false,
        NewPassword: "",
        ConfirmPassword: "",
        NewLicenseExtendedDate: "",
        userDetails: {
          email: "",
          phone: "",
          broker: {},
          license: {},
          licenceArr: [],
          NumberOfLicense: 0,
          amount: 0,
          showChangedPassword: false,
          SubscriptionExpiryDate: "",
          newLicenseSelected: null,
          existingLicense: [],
          stags: [],
        },
        email: "",
        phone: "",
        isShowing: false,
        isLoginByOtp: false,
        license: "PaperTrading",
        amount: 0,
        otp: null,
      },
      validationRegex,
      disabledFields: {
        changepassword: false,
        changeBroker: false,
        addLicense: false,
      },
    };
  },
  methods: {
    async licenseRenewFormEditSubmit() {


      this.newData =  {};
      const isNewChangesConfirmed = await this.$swal({
        title: "Confirm",
        text: "Are you sure you want to Update the data?",
        icon: "warning",
        backdrop: true,
        allowOutsideClick: false,
        showCancelButton: true,
        confirmButtonText: "Update",
      });
      if(!isNewChangesConfirmed.isConfirmed){
        return ;
      }
      this.newData = {
        Username: this.userDetails.data.MyLogin,
      };

      if (this.isEmailValid) {
        this.newData.Email = this.inputFields.userDetails.email;
      }

      if (this.isPhoneValid) {
        this.newData.Mobile = this.inputFields.userDetails.phone;
      }

      const isPasswordChange = this.inputFields.showChangedPassword;
      // const isPasswordChange = true;

      if (isPasswordChange) {
        //
        if (this.isPasswordValid) {
          this.newData.Password = this.inputFields.NewPassword;
          // newData.Password = "123";

          //     const isPasswordChanged = await this.changePassword();
          //     if (isPasswordChanged) {
          //       this.inputFields.showChangedPassword = false;
          //       this.disabledFields.changePassword = true;
          //     }
          //   } else {
          //     alert("Password not Valid");
        }
      }
      if (this.inputFields.showChangeBroker) {
        this.newData.AdminBrokerID = this.inputFields.userDetails.broker.BrokerValue;
        this.newData.BrokerBaseURI = this.inputFields.userDetails.broker.BrokerUrl;
        // newData.AdminBrokerID = this.inputFields.userDetails.broker.BrokerName
        //   const isBrokerChanged = await this.changeBroker();
        //   if (isBrokerChanged) {
        //     this.inputFields.showChangeBroker = false;
        //     this.disabledFields.changeBroker = true;
        //   }
      }
      let isNewLicenseSelected = false ;
      const newLicenseSelected = this.inputFields.userDetails.newLicenseSelected




      if (
        newLicenseSelected != "null" &&
        newLicenseSelected != null
      ) {
        if(newLicenseSelected.amount == 0){
          this.newData.ModuleID = this.inputFields.userDetails.newLicenseSelected.module_type;
        // this.newData.ModuleID = 1;
        this.newData.NumberOfLicense =  this.inputFields.userDetails.newLicenseSelected.number_of_license;
        const isDataModified = await this.modifyData(this.newData);
        if(isDataModified){
          this.$swal({
            title: "Success",
            text: "Data Updated Successfully",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });
        }

          this.inputFields.userDetails.newLicenseSelected = "null";
          return ;
        }

        isNewLicenseSelected = true;
        await this.addNewLicense();
        return ;
      }


      if (isNewChangesConfirmed.isConfirmed && !isNewLicenseSelected) {
        const isDataModifiedSuccessfully = await this.modifyData(this.newData);
        if (isDataModifiedSuccessfully) {
          this.$swal({
            title: "Success",
            text: "Data Updated Successfully",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });
        }
      }
      // const
    },
    async modifyData(newData) {
      // console.log({newData})
      const modifyUrl = `${this.URLS.proxyUrl}${this.URLS.api_base_url}api/user/modify`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append(
        "Authorization",
        `Bearer ${this.userDetails.data.Token}`
      );
      // console.log(`Bearer ${this.userDetails.data.Token}`)
      const requestObj = {
        method: "POST",
        body: JSON.stringify(newData),
        headers: jsonHeader,
      };
      // console.log({requestObj})
      const newDataModifyResp = await fetch(modifyUrl, requestObj);
      const modifyDataRespJson = await newDataModifyResp.json();

      return modifyDataRespJson.code == 200;
    },
    verifyRazorpayPayment,
    async addNewLicense() {
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", `Bearer ${this.userDetails.data.Token}`);
      const paymentDetailsObj = {
        name: this.userDetails.data.Name,
        email: this.userDetails.data.Email,
        phone: this.userDetails.data.Mobile,
        amount: this.newLicenseAmount,
        username: this.userDetails.data.Username,
      };
      // const paymentRespUrl = `${this.URLS.proxyUrl}${this.URLS.api_base_url}/api/forms/CreateRazorPayOrder`;
      const paymentObj = await this.fetchPaymentObj(
        jsonHeader,
        paymentDetailsObj,
        this.URLS
      );
      paymentObj.handler = this.paymentHandler;
      const rzp1 = new Razorpay(paymentObj);
      rzp1.open();
    },
    async paymentHandler(...params) {

      const paymentRespObj = params[0];
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", `Bearer ${this.userDetails.data.Token}`);
      const isPaymentVerified = await this.verifyRazorpayPayment(
        paymentRespObj,
        jsonHeader,
        this.URLS
      );
      if (isPaymentVerified) {
        // await this.createLicenses();
        // this.disabledFields.addLicense = true;
        await this.$swal({
            title: "Success",
            text: "Payment Done Successfully",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

        this.newData.ModuleID = this.inputFields.userDetails.newLicenseSelected.module_type;
        // this.newData.ModuleID = 1;
        this.newData.NumberOfLicense =  this.inputFields.userDetails.newLicenseSelected.number_of_license;
        const isDataModified = await this.modifyData(this.newData);
        if(isDataModified){
          this.$swal({
            title: "Success",
            text: "Data Updated Successfully",
            icon: "success",
            backdrop: true,
            allowOutsideClick: false,
          });

          this.inputFields.userDetails.newLicenseSelected = "null";
        }
      }
    },

    async changePassword() {
      const changePasswordUrlStr = `${this.URLS.admin_base_url}/Admin/resetUserPassword`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const requestObj = {
        method: "PUT",
        headers: jsonHeader,
        redirect: "follow",
        body: JSON.stringify({
          Username: this.userDetails.data.Username,
          NewPassword: this.inputFields.NewPassword,
          ConfirmPassword: this.inputFields.ConfirmPassword,
        }),
      };
      const changePasswordResp = await fetch(changePasswordUrlStr, requestObj);
      const changePasswordJSON = await changePasswordResp.json();

      const isPasswordChangeSuccessfull = changePasswordJSON.code == 200;
      if (isPasswordChangeSuccessfull) {
        alert("Password Changed Successfull");
      }
      return isPasswordChangeSuccessfull;


    },

    async changeBroker() {
      const changeBrokerUrl = `${this.URLS.admin_base_url}/Admin/ChangeUserBroker`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);

      const requestObj = {
        method: "PUT",
        headers: jsonHeader,
        body: JSON.stringify({
          UserID: this.userDetails.data.ID,
          AdminBrokerID: this.inputFields.userDetails.broker.BrokerValue,
        }),
      };
      const changeBrokerResp = await fetch(changeBrokerUrl, requestObj);
      const changeBrokerRespJson = await changeBrokerResp.json();

      const isBrokerChanged = changeBrokerRespJson.code == 200;
      if (isBrokerChanged) {
        alert("Broker Changed Successful");
      }

      return isBrokerChanged;
    },
    fetchPaymentObj,
    async createLicenses() {
      const createLicenseApiUrl = `${this.proxyUrl}${this.URLS.admin_base_url}/Admin/assignUserLicense`;
      const jsonHeaders = this.getJsonHeaders();
      jsonHeaders.add("Authorization", this.AUTH_DETAILS.ADMIN_API_TOKEN);
      const reqBody = JSON.stringify({
        UserID: this.AUTH_DETAILS.Admin_id,
        ModuleID: this.inputFields.userDetails.newLicenseSelected.module_type,
        NumberOfLicense: this.inputFields.userDetails.newLicenseSelected
          .number_of_license,
      });

      const reqObj = {
        method: "POST",
        body: reqBody,
        headers: jsonHeaders,
      };
      const resp = await fetch(createLicenseApiUrl, reqObj);
      const respJson = await resp.json();

      return respJson;
    },
    getJsonHeaders() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      return myHeaders;
    },
    async getAllLicence() {
      this.userDetails.data.licenceArr = [];
      const getAllLicenceUrl = `${this.URLS.proxyUrl}${this.URLS.api_base_url}api/forms/FetchLicenseList`;
      const jsonHeader = this.getJsonHeaders();
      jsonHeader.append("Authorization", this.AUTH_DETAILS.API_TOKEN);
      // console.log(this.AUTH_DETAILS);

      const reqObj = {
        headers: jsonHeader,
        method: "POST",
        redirect: "follow",
      };
      const getLicenceResp = await fetch(getAllLicenceUrl, reqObj);
      const getLicenceJson = await getLicenceResp.json();

      const licenceArr = getLicenceJson.data;

      this.userDetails.data.licenceArr = licenceArr;
      if (this.userDetails.data?.licenceArr?.length) {
        this.inputFields.userDetails.license = this.userDetails.data.licenceArr[0];
      }
    },

    async fetchBrokerList() {
      // const brokerListUrl = `${this.proxyUrl}${this.api_base_url}api/forms/FetchLicenseList`;
      const brokerListUrl = `${this.URLS.api_base_url}api/forms/FetchBrokerList`;

      const headers = new Headers();
      headers.append("Authorization", `Bearer ${this.userDetails.data.Token}`);

      const requestObj = {
        method: "GET",
        headers,
      };
      const resp = await fetch(brokerListUrl, requestObj);
      const brokerListDataObj = await resp.json();
      this.userDetails.data.BrokerList = brokerListDataObj.data;
    },
    async displayUserDetails() {
      this.isUserDetailsDisplayed = true;

      this.inputFields.userDetails.email = this.userDetails.data.Email;
      this.inputFields.userDetails.phone = this.userDetails.data.Mobile;
      this.inputFields.userDetails.NumberOfLicense = this.userDetails.data.NumberOfLicense;
      this.inputFields.userDetails.SubscriptionExpiryDate = this.userDetails.data.SubscriptionExpiryDate;
      this.inputFields.userDetails.license.ModuleID = this.userDetails.data.ModuleID;

      await this.fetchBrokerList();

      if (this.userDetails.data.BrokerList?.length) {
        if (this.userDetails.data.BrokerList.length > 1) {
          this.inputFields.userDetails.broker = this.userDetails.data.BrokerList.filter(
            (broker) =>
              broker.BrokerValue ==
              this.userDetails.data.BrokerID
          )[0];

          // console.log(this.userDetails.data.Broker.toLowerCase().trim());
        } else {
          this.inputFields.userDetails.broker = this.userDetails.data.BrokerList[0];
        }
      }
      if (this.userDetails.data.licenceArr?.length) {
        this.userDetails.data.currentLicense = this.userDetails.data.licenceArr.filter(
          (license) => this.userDetails.data.ModuleID == license.module_type
        )[0];
      }
      // this.getUserStags();
      // this.inputFields.userDetails.broker =
      // this.inputFields.userDetails.expiraryDate =
      // this.inputFields.userDetails.amount =
    },
    newLicenseSelected() {
      this.getNewLicenseExtendedDate();
    },
    getNewLicenseExtendedDate() {

      const newLicenseSelected = this.inputFields.userDetails
        .newLicenseSelected;
      if (newLicenseSelected != null && newLicenseSelected != "null") {
        let startDate = new Date();
        const lastLicenseDate = new Date(
          this.userDetails.data.ExpiryDate
        );
        lastLicenseDate.getTime() > startDate.getTime()
          ? (startDate = lastLicenseDate)
          : "";
        const numberOfNewLicense = newLicenseSelected.number_of_license;
        startDate.setDate(startDate.getDate() + 30 * numberOfNewLicense);
        this.inputFields.NewLicenseExtendedDate = startDate;
        return startDate.toLocaleDateString("en-CA");
      } else {
        this.inputFields.NewLicenseExtendedDate = null;

        return this.inputFields.NewLicenseExtendedDate;
      }
    },
  },
  computed: {
    isBrokerListVisible(){
      debugger;
      if(this.userDetails.data?.BrokerList?.length != undefined){
        if(this.userDetails.data.BrokerList.length > 2){

          return true 
        }
      }
      return false ;
    },
    newLicenseList() {
      // window.cc = this.userDetails.data.licenceArr
      // return []
      if (this.userDetails.data.licenceArr?.length) {
        return this.userDetails.data.licenceArr
        .filter((license) => {
          return license.module_type != 1;
        });
      }
      return [];
    },
    isNewLicenseListVisible(){
      if(this.userDetails.data?.licenceArr?.length != undefined){
        if(this.userDetails.data.licenceArr.length > 2){

          return true 
        }
      }
      return false ;
    },
    isPasswordValid() {

      if (
        this.inputFields.NewPassword != "" &&
        this.inputFields.ConfirmPassword != "" &&
        this.inputFields.ConfirmPassword == this.inputFields.NewPassword
      ) {
        return true;
      }
      return false;
    },
    newLicenseAmount() {
      // const currentModule = this.inputFields.userDetails.newLicenseSelected.module_type;
      const newLicenseSelected = this.inputFields.userDetails
        .newLicenseSelected;


      // }
      if(newLicenseSelected != null){
        return newLicenseSelected.amount ? newLicenseSelected.amount : 0 ;

      }
      return 0 ;

    },
    isEmailValid() {
      if (this.inputFields.userDetails.email.trim()) {
        return this.validationRegex.email.test(
          this.inputFields.userDetails.email
        );
      }
      return false;
    },
    isSubmitBtnDisabled() {

      const isSubmitBtnDisabled = false;

      if (this.inputFields.showChangedPassword) {
        if (!this.isPasswordValid) {
          return true;
        }
      }

      if (!this.isEmailValid) {
        return true;
      }
      if (!this.isPhoneValid) {
        return true;
      }
      if (this.inputFields.showChangeBroker) {
        if (
          this.inputFields.userDetails.broker == undefined ||
          !this.inputFields.userDetails.broker ||
          this.inputFields.userDetails.broker == null
        ) {
          return true;
        }
      }

      return isSubmitBtnDisabled;
    },
    isPhoneValid() {
      if (this.inputFields.userDetails.phone.trim()) {
        return this.validationRegex.mobile.test(
          this.inputFields.userDetails.phone
        );
      }
      return false;
    },
  },
};
</script>

<style scoped>
button.disabled {
  cursor: not-allowed !important;
}
.form-group label {
  margin: 10px;
}
.row {
  flex-wrap: wrap;
  align-items: center;
  justify-items: flex-start;
}
form {
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
select {
  max-width: 14rem;
}

@media (max-width: 700px) {
  select {
    max-width: 16rem;
  }
}
.form-control {
  min-width: 14rem;
  margin: auto 10px;
}
</style>

<style>
.userDetailsEditContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  text-align: center;
}
.form-group label {
  text-align: left;
}
</style>
